<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xml:space="preserve"
    width="24"
    height="24"
    fill="currentColor"
  >
    <path
      d="m12.3 18.4 5.9-5H0v-2.8h18.1l-5.8-4.8 2-2.2 9.8 8.4-9.7 8.3c-.1.1-2.1-1.9-2.1-1.9z"
    />
  </svg>
</template>
